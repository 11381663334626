<template>
    <div>
        <div class="section">
            <div class="section-row">
                <div class="section-col">
                <span class="fz12">开始时间：</span>
                <div class="section-date-start" @click="onDatePickerOpen('startDate')">
                    {{ startDate }}
                </div>
                </div>
                <div class="section-col">
                <span class="fz12">结束时间：</span>
                <div class="section-date-end" @click="onDatePickerOpen('endDate')">
                    {{ endDate }}
                </div>
                </div>
            </div>
        </div>
        <div class="booking-list">
            <van-list
                v-model="listLoading"
                :finished="listFinished"
                :finished-text="listFinishedText"
                @load="onLoad"
                >
                <div v-for="(item,index) in showList" :key="index">
                    <div class="booking-time">{{dayjs(item.key).format("YYYY-MM-DD dddd")}}</div>
                    <van-cell v-for="it in item.data" :key="it.book_id" :value="it.book_status" :label="it.course_name">
                        <div style="color:#969799" slot="title">{{it.class_start}}-{{it.class_end}} {{it.teacher_name}}</div>
                    </van-cell>
                </div>

            </van-list>
        </div>
        <van-popup v-model="dateShowPicker" position="bottom">
            <van-datetime-picker
                v-model="currentTime"
                type="date"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="onDatePickerConfirm"
                @cancel="onDatePickerClose"
            />
        </van-popup>
        <loadings :loading='listLoading && pageCurrent==1' />
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import 'dayjs/locale/zh-cn'
    import Loadings from '@/components/loading'
    import { DatetimePicker,Picker } from 'vant'
    export default {
        name:'booking',
        data() {
            // const nowDate = dayjs(nowDate).format('YYYY-MM-DD')
            // const startMonth = dayjs().startOf('month').format('YYYY-MM-DD')
            return {
                dateType: 'startDate',
                startDate: '',
                endDate: '',
                minDate: new Date(2021, 0, 1),
                maxDate: new Date(),
                currentTime:'',
                dateShowPicker: false,
                listLoading: false,
                list: [],
                showList:[],
                listFinished:false,
                listFinishedText: '没有更多了',
                pageSize: 10,
                pageCurrent: 1,
            }
        },
        components: {
            [DatetimePicker.name]: DatetimePicker,
            [Picker.name]: Picker,
            loadings:Loadings,
        },
        created () {
            dayjs.locale('zh-cn')
        },
        methods: {
            dayjs,
            getList(){
                let obj = {
                    'per-page': this.pageSize, 
                    page: this.pageCurrent, 
                    student_id:this.$route.query.student_id,
                    // student_id:641,
                    search:{
                        start_date:this.startDate,
                        end_date:this.endDate,
                    }
                }
                this.listLoading = true;
                this.$api.qywx_external_contact_course( obj ).then(res => {
                    this.listLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.showList = this.group(this.list,"class_date")
                    this.listFinished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
                    this.pageCurrent += 1
                })
            },
            onDatePickerOpen(type) {
                if(type == 'startDate'){
                    this.currentTime = this.currentStart
                } else{
                    this.currentTime = this.currentEnd
                }
                this.dateType = type
                this.dateShowPicker = true
            },
            onDatePickerClose() {
                this.dateShowPicker = false
                this.list = [];
                this.getList()
            },
            formatter(type, val) {
                if (type === 'year') {
                    return `${val}年`
                } else if (type === 'month') {
                    return `${val}月`
                } else {
                    return `${val}日`
                }
            },
            onDatePickerConfirm(time) {
                if (this.dateType === 'endDate') {
                    this.currentEnd = time;
                    const timestampEnd = dayjs(time).valueOf()
                    const timestampStart = dayjs(this.startDate).valueOf()
                    if (timestampEnd < timestampStart) {
                    this.$toast('结束时间不能小于开始时间')
                    return
                    }
                }else{
                    this.currentStart = time;
                }
                this[this.dateType] = dayjs(time).format('YYYY-MM-DD')
                this.onDatePickerClose()
            },
            onLoad(){
                this.getList()
            },
            group(arr,key) {
                var map = {}, dest = [];
                for (var i = 0; i < arr.length; i++) {
                    var ai = arr[i];
                    if (!map[ai[key]]) {
                        dest.push({
                                key: ai[key],
                                data: [ai]
                            });
                                map[ai[key]] = ai;
                            }else {
                                for (var j = 0; j < dest.length; j++) {
                                    var dj = dest[j];
                                if (dj.key == ai[key]) {
                                    dj.data.push(ai);
                                break;
                            }
                        }
                    }
                }
                return dest
            }
        },
    }
</script>

<style lang="less" scoped>
    .section{
        background: #fff;
        &-row{
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        }
        &-col{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        }

        &-date{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-start{
            box-sizing: border-box;
            width: 100px;
            height: 30px;
            border-radius: 4px;
            padding: 4px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #666;
            font-size: 12px;
            border: 1px solid #eee;
        }
        &-end{
            box-sizing: border-box;
            width: 100px;
            height: 30px;
            border-radius: 4px;
            padding: 4px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #666;
            font-size: 12px;
            border: 1px solid #eee;
        }
        }
    }
    .booking-time{
        padding: 3px 0px 3px 10px;
        font-size: 12px;
    }
    .booking-list{
        .van-cell__value{
            flex: inherit;
        }
    }
</style>